<template>
  <v-app class="transparent">
    <v-container>

      <change-request-form>
      </change-request-form>

    </v-container>
  </v-app>
</template>
<script>
  import ChangeRequestForm from './ChangeRequestForm'

  export default {
    components:{
      'change-request-form': ChangeRequestForm,
    },
  }

</script>

<style scoped>

</style>
