<template>
  <v-app class="transparent">
    <v-container
      id="sms-change-request"
      fluid
      tag="section">
      <VValidationErrors :validation-errors="validationErrors"></VValidationErrors>
      <base-material-card
        icon="mdi-bell-plus-outline"
        title="Create SMS Change Request"
        class="px-5 py-3">
        <v-form
          ref="form"
          on-submit="return false;"
          @submit="submit"
        >
          <v-container class="py-0">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="smsData.sms_text"
                  :rules="rules.sms_text"
                  label="Sms Text"
                  class="purple-input"
                  required
                  outlined
                />
              </v-col>
              <v-col
                cols="12">
                <v-btn
                  color="success"
                  class="mr-0"
                  @click="submit">
                  Create Change Request
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </base-material-card>
    </v-container>
  </v-app>
</template>
<script>
  import axios from 'axios'
  import Vue from 'vue'
  import VValidationErrors from '../../components/base/VValidationErrors'
  export default {
    components: { VValidationErrors },
    data: () => ({
      durations : [],
      smsData: {
        sms_text: '',
        duration_id: '',
      },
      rules: {
        sms_text: [
          v => !!v || 'SMS text is required',
        ],
      },
      validationErrors: {},
    }),
    created () {
      this.getSmsData()
    },
    methods: {
      getSmsData() {
        this.$authApi.get(`/partner-sms/${ this.$route.params.duration }`).then(res => {
          //this.userData = res.data.data.username;
          this.smsData = {
            sms_text: res.data.data.sms_text,
            duration: res.data.data.duration,
            duration_id: res.data.data.duration_id,
          }
        });

      },
      submit () {
        if (!this.$refs.form.validate()) {
          return
        }
        this.addChangeRequest()
      },
      addChangeRequest () {
        this.$authApi.post('/sms-change-requests', this.smsData)
          .then(res => {
            this.flashMessage.success({
              title: 'Change Request Created  Successfully',
              message: `Your SMS change request has been created successfully`,
            })
            this.$router.push(`/sms/change-requests/${ this.$route.params.duration }`)
          }).catch(error => {
          if (error.response) {
            this.validationErrors = error.response.data.errors;
          }
        })
      },
    },
  }
</script>
